<template>
  <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.625 14C2.625 7.71776 7.71776 2.625 14 2.625C20.2822 2.625 25.375 7.71776 25.375 14C25.375 20.2822 20.2822 25.375 14 25.375C7.71776 25.375 2.625 20.2822 2.625 14ZM18.212 11.8836C18.4929 11.4903 18.4018 10.9439 18.0086 10.663C17.6153 10.3821 17.0689 10.4732 16.788 10.8664L13.0133 16.1509L11.1187 14.2563C10.777 13.9146 10.223 13.9146 9.88128 14.2563C9.53957 14.598 9.53957 15.152 9.88128 15.4937L12.5063 18.1187C12.6882 18.3006 12.9408 18.3932 13.1972 18.372C13.4535 18.3508 13.6875 18.2179 13.837 18.0086L18.212 11.8836Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckCircleSolidIcon",
}
</script>

<style lang="scss" scoped></style>
